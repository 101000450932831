<script>
// import Loader from "./Loader.vue";

export default {
    name: "AIComponent",
    data() {
        return {
            isOpen: false,
            messages: [],
            newMessage: "",
            errorMessage: "",
            sessionId: "",
            initialSessionId: "",
            initialLoad: true,
            initialMessage: "",
            loading: false,
            hasResetConvo: false,
            agentId: "asst_l3v7gGSzdGysunu4fPG9cgfE"
        };
    },
    mounted() {
        // If you had any code in onMounted, it will go here
    },
    methods: {
        resetConvo() {
            const messagesToStore =
                this.messages.length > 2
                    ? this.messages.slice(0, 2)
                    : this.messages;
            localStorage.setItem("messages", JSON.stringify(messagesToStore));

            this.hasResetConvo = true;
            this.messages = [];
            this.newMessage = "";
            this.sessionId = "";
        },
        resumeInitialConvo() {
            this.messages = JSON.parse(localStorage.getItem("messages"));
        },
        chatCompletion(data) {
            return fetch(
                "https://weeyw25qu6uryw75hyohog3u6m0taupr.lambda-url.us-east-1.on.aws/",
                {
                    method: "POST",
                    headers: {
                        Accept: "text/plain, */*",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                }
            ).then((response) => {
                if (!response.body) {
                    throw new Error(
                        "Readable stream not supported in this browser"
                    );
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let partialText = "";

                return new ReadableStream({
                    async start(controller) {
                        try {
                            let done = false;
                            while (!done) {
                                const { done: isDone, value } =
                                    await reader.read();
                                done = isDone; // Breaks the loop when done is true
                                if (done) {
                                    controller.close();
                                    break;
                                }

                                const decodedValue = decoder.decode(value, {
                                    stream: true
                                });

                                partialText += decodedValue;
                                controller.enqueue(decodedValue);
                            }
                        } catch (error) {
                            console.error("Error reading stream:", error);
                            controller.error(error);
                        }
                    }
                });
            });
        },
        async sendMessage(query) {
            console.log("sendMessage", query);
            if (query !== "") {
                this.messages.push({ sender: "user", messageText: query });
                this.newMessage = "";
                this.loading = true;

                this.errorMessage = null;

                try {
                    this.chatCompletion({
                        message: query,
                        sessionId: this.sessionId,
                        agentId: this.agentId
                    })
                        .then((stream) => {
                            const reader = stream.getReader();

                            let messageCount = 0;
                            let messageIndex = null;

                            reader.read().then(
                                function processText({ done, value }) {
                                    if (done || !value) {
                                        return;
                                    }

                                    console.log("value", value);
                                    if (
                                        value.startsWith("【") ||
                                        value.endsWith("】")
                                    ) {
                                        return reader
                                            .read()
                                            .then(processText.bind(this));
                                    }

                                    if (messageCount === 0) {
                                        this.sessionId = value;
                                    } else if (messageCount === 1) {
                                        this.loading = false;
                                        this.messages.push({
                                            sender: "bot",
                                            messageText: value
                                        });

                                        messageIndex = this.messages.length - 1;
                                    } else {
                                        this.messages[
                                            messageIndex
                                        ].messageText += value;
                                    }

                                    messageCount++;
                                    return reader
                                        .read()
                                        .then(processText.bind(this));
                                }.bind(this)
                            );
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                } catch (error) {
                    console.error("Error submitting message:", error);
                    this.errorMessage = error.message + ". Please try again.";
                } finally {
                    this.initialLoad = false;
                }
            } else {
                alert("Please enter a message");
                return;
            }
        },
        handleNewMessage() {
            this.sendMessage(this.newMessage);
        },
        calculatePadding(text) {
            return text.length > 50 ? "py-4 px-6" : "py-2 px-4";
        },
        cleanMessageText(text) {
            const removeSourceAndSpacing =
                /\s*<source>.*?<\/source>\s*|<sources>.*?<\/sources>\s*/gis;
            return text.replace(removeSourceAndSpacing, "");
        },
        toggleChat() {
            this.isOpen = !this.isOpen;
        }
    }
};
</script>

<template>
    <!-- Chat Window -->
    <div class="AIComponent-window">
        <button
            @click.prevent="toggleChat"
            v-if="!isOpen"
            class="AIComponent-collapsed"
        >
            <span class="AIComponent-collapsed--inner">AI Chatbot</span>
        </button>
        <div @click.prevent="toggleChat" v-else class="AIComponent-open">
            <div
                @click.stop
                class="w-full flex flex-col overflow-hidden AIComponent-open--inner h-full"
            >
                <div
                    class="flex flex-col-reverse md:flex-row w-full justify-between mb-8"
                >
                    <h2 class="my-0">EAIE 2024 AI Chatbot</h2>
                    <button @click.prevent="toggleChat" class="ml-auto bg-none">
                        <svg
                            fill="#000000"
                            height="800px"
                            width="800px"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 460.775 460.775"
                            xml:space="preserve"
                        >
                            <path
                                d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
	c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
	c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
	c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
	l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
	c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"
                            />
                        </svg>
                    </button>
                </div>
                <div
                    class="overflow-hidden flex flex-col grow bg-transparent border-t"
                >
                    <!-- Chat Messages -->
                    <div
                        class="overflow-x-visible overflow-y-auto grow flex flex-col space-y-2 rounded-t-lg"
                        :class="{
                            'py-4': messages.length > 0
                        }"
                    >
                        <div v-if="messages.length > 0" class="space-y-2 px-2">
                            <div
                                v-for="(message, index) in messages"
                                :key="index"
                                :class="{
                                    'user ml-auto': message.sender === 'user',
                                    bot: message.sender !== 'user',
                                    [calculatePadding(
                                        message.messageText
                                    )]: true
                                }"
                                class="rounded-xl text-left w-fit max-w-[95%] md:max-w-[85%] shadow-md font-sans leading-none h-auto AIComponent--message"
                            >
                                <pre
                                    class="font-sans text-pretty border-0 m-0 p-0 text-lg leading-tight overflow-hidden"
                                    >{{ message.messageText }}</pre
                                >
                            </div>
                        </div>
                        <div v-else class="mx-auto my-auto text-lg text-center">
                            <p class="text-xl">
                                Ask a question about sessions, speakers, or
                                attendees.
                            </p>
                            <p class="mb-8">
                                AI is experimental and may sometimes give wrong
                                answers.
                            </p>
                        </div>
                        <div
                            v-if="loading"
                            class="relative flex w-fit max-w-[85%]"
                        >
                            <div
                                class="z-30 rounded-xl text-left py-1 px-6 w-fit h-8 AIComponent--message bot"
                            >
                                <span class="animated-gradient-text font-sans">
                                    Loading...</span
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="errorMessage"
                        class="p-4 mb-2 bg-red-500 text-white text-center"
                    >
                        {{ errorMessage }}
                    </div>

                    <!-- Message Input -->
                    <form
                        @submit.prevent="handleNewMessage"
                        class="border-t flex flex-col max-w-full"
                    >
                        <textarea
                            v-model="newMessage"
                            placeholder="Type a message..."
                            class="flex-grow p-2 border border-1 border-gray-500/50 resize-none focus:outline-none mb-2 font-sans"
                            :class="{
                                'rounded-b': messages.length > 0,
                                rounded: messages.length === 0
                            }"
                            rows="3"
                            @keydown.enter.exact.prevent="handleNewMessage"
                        ></textarea>
                        <div class="flex justify-between">
                            <button
                                type="button"
                                @click.prevent="resetConvo"
                                class="px-4 border border-1 border-indigo-500 border-solid rounded-full text-base mt-2 font-normal has-outline-style"
                                :class="{
                                    'opacity-0 hover:opacity-0 cursor-default':
                                        messages.length === 0,
                                    'hover:opacity-75 hover:bg-white/50 cursor-pointer':
                                        messages.length > 0
                                }"
                            >
                                Start a new conversation
                            </button>
                            <button
                                type="submit"
                                class="AIComponent-submit-button ext-white px-4 py-2 rounded focus:outline-none w-fit ml-auto mt-2 text-base no-underline font-normal hover:opacity-75 font-sans bg-none shadow-none"
                            >
                                Send
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
